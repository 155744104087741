body {
    height: auto;
    width: auto;
    margin:0px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/back.png") no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.Home {
    height: fit-content;
}

@media only screen and (max-width: 768px) {
    body {
        background-position-x: 70%;
    }
    .container {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.foot {
    position: fixed;
    bottom: 0;
    width: 100%;
}


@media only screen and (min-width: 768px) {
    .container {
        width: 60%;
        height: fit-content;
        padding-left: 30px;
        padding-bottom: 20px;
    }
}
.container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    /*Done to make hero section more clear*/
    filter: blur(0px);
    position: absolute;
    top: 100px;
    padding-bottom: 150px;
}

