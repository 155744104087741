

.wrapperS {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    justify-content: center;
    position:relative;
    top: 100px;
    padding-bottom: 150px;
}

@media only screen and (min-width: 725px) and (max-width: 1100px) {
    .wrapperS {
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 725px) {
    .wrapperS {
        grid-template-columns: repeat(2, auto);
    }
}