@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
/* bg-black 
flex 
justify-between 
items-center 
h-24 
max-w-[1240px] 
mx-auto 
px-4 
text-white */
.Na {
    /* background-color: black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    color: white;
    font-size: 50px;
    text-decoration: none;
    position: relative;
    z-index: 1;
}
a:-webkit-any-link {
    text-decoration: none;
    color: white;
}

ul {
    list-style: none;
}
li {
    list-style: none;
}

.Na img {
    width: 60px;
}

.Na img:hover {
    transform: scale(1.02);
}

/* hidden md:flex */
.desktop {
    display: none;
}

@media only screen and (min-width: 768px) {
    .desktop {
        display: flex;
    }
    .Nav_b {
        display: none;
    }
    .mobile {
        display: none;
    }
    .Na img {
        width: 80px;
    }
}

.Nav_b {
    padding-bottom: 5px;
    border-radius: 0.5rem;
    transition-duration: 300ms;
}

.Nav_b:hover {
    cursor: pointer;
    scale: 1.12;
    background-color: blueviolet;
}

/* p-4 
hover:bg-[#00df9a] 
rounded-xl 
m-2 
cursor-pointer 
duration-300 
hover:text-black */
.list {
    padding: 1rem;
    border-radius: 0.75rem;
    margin: 0.5rem;
    cursor: pointer;
    transition-duration: 300ms;
}

.list:hover {
    background-color: blueviolet;
    color: black;
}


/* block 
md:hidden */


/* fixed 
md:hidden 
left-0 
top-0 
w-[60%]
h-full
border-r 
border-r-gray-900 
bg-[#000300] 
ease-in-out 
duration-500 */
.mobile {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 60%;
    height: 100%;
    border-right-width: 1px;
    border-right-color: rgb(17, 24, 39);
    background-color: #000300;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    list-style: none;
    margin: 0px;
    padding-left: 0px;
}

/* ease-in-out
w-[60%] 
duration-500 
fixed 
top-0 
bottom-0 
left-[-100%] */
.mobile_b {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    width: 60%;
    transition-duration: 500ms;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: -100%;
    list-style: none;
}

.i {
    padding-top: 10px;
    padding-left: 10px;
}

/* p-4 
border-b 
rounded-xl 
hover:bg-[#00df9a] 
duration-300 
hover:text-black 
cursor-pointer 
border-gray-600 */
.mobile_items {
    padding: 1rem;
    border-bottom: 1px solid white;
    border-radius: 0.75rem;
    transition-duration: 300ms;
    cursor: pointer;
    font-size: 30px;
}

.mobile_items:hover {
    background-color: blueviolet;
    color: black;
}
.footer {
    width: 100%;
    height: 100px;
    /* background-color: #121619; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    padding-left: 0px;
    padding-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background-color: rgb(53, 52, 52);
}

@media only screen and (max-width: 768px) {
    .footer {
        height: 60px;
    }

    .socialMedia svg {
        font-size: 25px;
        margin: 0px;
    }
    .copy {
        font-size: 10px;
    }
}

@media only screen and (min-width: 768px) {
    .socialMedia svg {
        font-size: 50px;
        margin: 10px;
    }
    .copy {
        font-size: 20px;
    }
}

.socialMedia svg {
    color: white;
    cursor: pointer;
}

.socialMedia svg:hover{
    color: #FF00FF;
}

.copy {
    color: white;
    margin: 5px;
}

.footer p{
    text-align: center;
    padding-bottom: 15px;
}
.Hero {
    justify-content: left;
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex: 50% 1;
    animation: 2s ease-out 0s 1 slideUpAnimation;
    color: white;
}

@media only screen and (max-width: 575px) {
    .Hero {
        width: 100%;
    }
    .Hero .Title {
        font-size: 40px;
    }
    .Hero .AboutMe {
        font-size: 20px;
        font-weight: bold;
    }
}

@media only screen and (max-width: 768px) and (min-width: 575px) {
    .Hero .Title {
        font-size: 50px;
    }
    .Hero .AboutMe {
        font-size: 25px;
        font-weight: bold;
    }
}

/*Make hero section slide up into place on screen load*/
@keyframes slideUpAnimation {
    0% {
        transform: translateY(100%);
        color: blueviolet;
    }
    50% {
        color: blueviolet;
    }
    100% {
        transform: translateY(0);
        color: white;
    }
}

@media only screen and (min-width: 768px) {
.Hero .Title {
    font-size: 60px;
}

.Hero .AboutMe {
    font-size: 27px;
    font-weight: bold;
}
}

.b {
    display: inline-block;
    padding: 10px 15px;
    margin-top: 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: white;
    background-color: blueviolet;
    border: none;
    border-radius: 13px;
    box-shadow: 0 9px white;
}

.b:hover {
    background-color: #5b0a8a;
}

.b:active {
    background-color: blueviolet;
    box-shadow: 0 5px white;
    transform: translateY(4px);
}
body {
    height: auto;
    width: auto;
    margin:0px;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/back.fe97c38e.png) no-repeat center center;
    background-size: cover;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.Home {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

@media only screen and (max-width: 768px) {
    body {
        background-position-x: 70%;
    }
    .container {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.foot {
    position: fixed;
    bottom: 0;
    width: 100%;
}


@media only screen and (min-width: 768px) {
    .container {
        width: 60%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-left: 30px;
        padding-bottom: 20px;
    }
}
.container {
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /*Done to make hero section more clear*/
    filter: blur(0px);
    position: absolute;
    top: 100px;
    padding-bottom: 150px;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}


.wrapperP {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    position:relative;
    top: 50px;
    padding-bottom: 150px;
    animation: 2s ease-out 0s 1 slideDownAnimation;
}

@media only screen and (min-width: 550px) and (max-width: 1050px) {
    .wrapperP {
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 550px){
    .wrapperP {
        grid-template-columns: repeat(2, auto);
    }
}

@keyframes slideDownAnimation {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@media only screen and (max-width: 725px) {
    .card_p {
        height: 365px;
        width: 200px;
    }
    .card__image {
        height: 14rem;
    }
    .card__title {
        font-size: 1.5rem;
    }
    .card__description {
        font-size: 1.5rem;
    }
    .card__btn {
        padding: 0.5rem 1rem 0.5rem 1rem;
    }
}

@media only screen and (min-width: 725px) {
    .card_p {
        height: 475px;
        max-width: 250px;
    }
    .card__image {
        height: 17rem;
    }
    .card__title {
        font-size: 2rem;
    }
    .card__description {
        font-size: 2rem;
    }
    .card__btn {
        padding: 1rem 2rem 1rem 2rem;
    }
}

.card_p {
    overflow: hidden;
    background-color: lightgray;
    box-shadow: 0 2px 20px #e1e5ee;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 200ms ease-in;
    animation: 2s ease-out 0s 1 fadeIn;
}

@keyframes fadeIn {
    0% {
        opacity: 0;;
    }
    100% {
        opacity: 1;
    }
}

.card__image {
    width: 100%;
    object-fit: cover;
}
  
.card__title {
    margin-top: 0;
    margin-bottom: 0;
    color: blueviolet;
}
  
.card__description {
    margin-top: 0;
    margin-bottom: 0;
    color: blueviolet;
}
.bottom {
    justify-content: center;
    display: flex;
}
  
.card__btn {
    font-family: inherit;
    font-weight: bold;
    font-size: 2rem;
    margin: 1rem 3.25rem;
    border: 2px solid blueviolet;
    background: transparent;
    background-color: white;
    color: blueviolet;
    border-radius: 0.2rem;
    transition: background 200ms ease-in, color 200ms ease-in;
}
  
.card_p:hover {
    transform: scale(1.02);
}
  
.card_p:hover .card__btn {
    background: blueviolet;
    color: white;
}
.FAQ {
    position: relative;
    padding-bottom: 150px;
    animation: 2s ease-out 0s 1 slideDownAnimation;
    padding-right: 5%;
    padding-left: 5%;
}


@media only screen and (max-width: 350px) {
    .FAQ {
        padding-right: 0%;
        padding-left: 25%;
    }
}

/* Color is not changing fix later */
@keyframes slideDownAnimation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
.Card {
    overflow: hidden;
    background-color: rgb(140, 64, 211);
    box-shadow: 0 2px 20px #e1e5ee;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 200ms ease-in;
    height: 35rem;
    width: 350px;
    animation: 2s ease-out 0s 1 fadeIn;
}

@keyframes fadeIn {
    0% {
        opacity: 0;;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 725px) {
    .Card {
        height: 17rem;
        width: 200px;
    }
}

.Card__body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Card__img {
    height: 90%;
    width: 90%;
    object-fit: cover;
    display: flex;
    justify-content: center;
}

.Card:hover {
    transform: scale(1.02);
}


.wrapperS {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    justify-content: center;
    position:relative;
    top: 100px;
    padding-bottom: 150px;
}

@media only screen and (min-width: 725px) and (max-width: 1100px) {
    .wrapperS {
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 725px) {
    .wrapperS {
        grid-template-columns: repeat(2, auto);
    }
}


.wrapperM {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    justify-content: center;
    position:relative;
    top: 100px;
    padding-bottom: 150px;
}

@media only screen and (min-width: 725px) and (max-width: 1100px) {
    .wrapperM {
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 725px) {
    .wrapperM {
        grid-template-columns: repeat(2, auto);
    }
}
.wrapperL {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    justify-content: center;
    position:relative;
    padding-bottom: 150px;
}

@media only screen and (min-width: 725px) and (max-width: 1100px) {
    .wrapperL {
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 725px) {
    .wrapperL {
        grid-template-columns: repeat(2, auto);
    }
}
.wrapperXL {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    justify-content: center;
    position:relative;
    top: 100px;
    padding-bottom: 150px;
}

@media only screen and (min-width: 725px) and (max-width: 1100px) {
    .wrapperXL {
        grid-template-columns: repeat(2, auto);
    }
}

@media only screen and (max-width: 725px) {
    .wrapperXL {
        grid-template-columns: repeat(2, auto);
    }
}

* {
    scroll-behavior: smooth;
  }

  .MuiButton-root:disabled {
    opacity: 30%;
    background-color: gray;
  }

  .MuiButton-root:hover {
    transform: scale(1.1);
    transition: 0.3s cubic-bezier(.47,1.64,.41,.8);
    /* transform: scale(1.1), */
  }

  .MuiIconButton-root:hover {
    transform: scale(1.1);
    transition: 0.3s cubic-bezier(.47,1.64,.41,.8);
    /* transform: scale(1.1), */
  }


